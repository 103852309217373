/* global process */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Favicon from 'react-favicon'
import classNames from 'classnames'

import '../styles/brands/generic.scss'
import '../styles/brands/nissan.scss'
import '../styles/brands/infiniti.scss'
import '../styles/_general.scss'
import '../styles/_common.scss'

const ThemeContainer = ({ currentBrand, children }) => {
  useEffect(() => {
    if (currentBrand) {
      const brand = currentBrand.toLowerCase()
      if (brand === 'nissan') {
        import('../styles/_dark-theme-nissan.scss').catch(() =>
          console.warn('Nissan theme not found')
        )
      } else if (brand === 'infiniti') {
        import('../styles/_dark-theme-infiniti.scss').catch(() =>
          console.warn('Infiniti theme not found')
        )
      }
    }
  }, [currentBrand])

  const brand = currentBrand?.toLowerCase() || 'generic'

  return (<div className={classNames("container", brand ? brand : "generic")}>
    <Favicon url={`${process.env.PUBLIC_URL}/images/favicon${brand ? "_" + brand : ""}.ico`} />
    {children}
  </div>)
}

ThemeContainer.propTypes = {
  currentBrand: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.func
  ])
}

const mapStateToProps = ({ vehicle: { currentBrand } }) => ({ currentBrand })

export default connect(mapStateToProps)(ThemeContainer)
