import React, { useState, useEffect } from "react"
import arrow_right from "../../images/arrow_right.svg"
import PropTypes from "prop-types"

const HorizontalListNavigator = ({ elements = [] }) => {
  const [visibleCount, setVisibleCount] = useState(elements.length) // Number of elements visible at a time
  const [startIndex, setStartIndex] = useState(0) // Start index of the current view

  useEffect(() => {
    const calculateVisibleCount = () => {
      // Dynamically calculate based on the screen width and item width (assume each item has a fixed width of 150px and margin of 10px)
      const itemWidth = 100
      const screenWidth = window.innerWidth
      const calculatedCount = Math.floor(screenWidth / itemWidth)

      // If all elements fit, set visibleCount to the total number of elements
      setVisibleCount(calculatedCount >= elements.length ? elements.length : calculatedCount)
      if (calculatedCount >= elements.length) {
        setStartIndex(0) // Reset start index if all elements fit
      }
    }

    calculateVisibleCount() // Calculate initially

    // Recalculate when the window resizes
    window.addEventListener("resize", calculateVisibleCount)
    return () => window.removeEventListener("resize", calculateVisibleCount)
  }, [elements.length])

  const handleNext = () => {
    if (startIndex + visibleCount < elements.length) {
      setStartIndex(startIndex + visibleCount)
    }
  }

  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - visibleCount)
    }
  }

  const visibleElements = elements.slice(
    startIndex,
    startIndex + visibleCount
  )

  const showButtons = visibleCount < elements.length

  return (
    <>
      {showButtons && startIndex > 0 && (
        <button className="VehicleYearSelector__nav-button" onClick={handlePrevious}>
          <img style={{transform: "rotate(180deg)"}} src={arrow_right}/>
        </button>
      )}
      {visibleElements.map((element, index) => (
        <React.Fragment key={index}>{element}</React.Fragment>
      ))}
      {showButtons && startIndex + visibleCount < elements.length && (
        <button className="VehicleYearSelector__nav-button" onClick={handleNext}>
          <img src={arrow_right}/>
        </button>
      )}
    </>
  )
}

HorizontalListNavigator.propTypes = {
  elements: PropTypes.array.isRequired,
}

export default HorizontalListNavigator